$dark-text-color: #222;

*,
*:before,
*:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
}
html,body{
    height: 100%;
    padding:0;
    margin:0;
}
body {
    font-family: "Archivo", sans-serif;
    padding: 0;
    margin: 0;
    color: $dark-text-color;
}

.border {
    border: 0.5px rgba(0, 0, 0, 0.3) solid;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3);
}

.divider {
    height: 1px;
    width: 100%;
    background: rgba(0, 0, 0, 0.4);
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.3);
}

a {
    color: $dark-text-color;
}

a:hover {
    text-decoration: none;
}

a.contact-link {
    color: #7fe594;
}
a.contact-link:hover {
    text-decoration: underline;
}